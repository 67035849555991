.portfolio{
    background-color: var(--secondary);
    height: inherit;
}
.realizations{
    margin-top: 80px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 5rem;
}

.portfolio .btn{
    margin: 80px auto 40px;
}