.contact{
    height: inherit;
    background:linear-gradient(0deg,hsl(222, 50%, 8%, .5), hsl(222, 50%, 8%, .5)), url('../../assets/img/contact.webp');
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
}
.contact-mail{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    color:var(--color1);
    text-decoration: none;
}
.contact-mail-icon{
    color:var(--primary);
    margin-right: 6px;
    font-size:26px;
}
.contact-mail a{
    color:var(--color1);
    text-decoration: none;
}
.contact-form{
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: inherit;
    align-items: center;
    justify-content: center;
    gap: 5rem;
}
 
.contact-form-img{
    border-bottom: 1px solid var(--primary);
    border-left: 1px solid var(--primary);
    margin: 0 auto;
    padding: 20px;
    position: relative;
}
 
 
.contact-form-img::before{
    content: '';
    background-color: var(--primary);
    height: 50px;
    width:50px;
    position: absolute;
    top:-25px;
    left:-25px;
    transform: rotate(45deg);
}
.contact-form-img::after{
    content: '';
    background-color: var(--primary);
    height: 50px;
    width: 50px;    
    position: absolute;
    bottom:-50px;
    right: -50px;
     
}

@media only screen and (max-width: 1440px) {
    .contact-form-img{
        padding: 0px;
    }
    .contact-form-img img{
        width:100%;
        height: 100%;
    }
  }
  @media only screen and (max-width: 1080px) {
    .contact-form-img img{
        width:70%;
        height: 70%;
    }
    .contact-form{
        gap: 4rem;
    }
  }
  @media only screen and (max-width: 1080px) {
    .contact-form{
        grid-template-columns: 1fr;
    }
    .form{
        order: 1;
    }
    .contact-form-img{
        order: 2;
    }
  }
  @media only screen and (max-width: 764px) {
    .contact-form-img{
        border-left:none;
        border-bottom: none;
    }
    .contact-form-img::after, .contact-form-img::before{
        display: none;
  }
}