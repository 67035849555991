.scroll-top{
    position:fixed;
    right: 30px;
    bottom:30px;
    border:1px solid var(--primary);
    width:50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    display: none;
    cursor: pointer;
  }
  .scroll-icon{
    color: var(--primary);
  }
  .scroll-active{
    display: flex;
  }