.navbar{
    height: 100px;
    background: var(--secondary);
    color:var(--color1);
    display: flex;
    align-items: center;
}
.nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.logo{
    color:var(--primary);
    font-size:24px;
    border:1px solid var(--primary);
    padding: 4px 18px;
    font-family: 'Oswald';
}
.menu{
    list-style-type: none;
    display: flex;
    gap: 1rem;
    justify-content: center;
    font-size: 26px;
    text-transform: uppercase;
    font-family: 'Oswald';
}
.menu li{
    cursor: pointer;
}
.menu li:hover{
    color:var(--primary)
}
                   
.menu-icon{
    font-size: 24px;
    cursor: pointer;
}

.mobile-nav{
    display: none;
}


@media only screen and (max-width: 800px) {
    .mobile-nav{
        display: block;
    }
    .menu{
        flex-direction: column;
        position: absolute;
        z-index: 1;
        top:0;
        left:-100%;
        background-color: var(--secondary);
        width:75%;
        padding:50px;
        transition: .3s;
    }
    .menu li{
        border-bottom: 1px solid var(--primary);
        padding-bottom: 20px;
    }
    .menu li a{
        display: block;
    }
    .mobile-active{
        left:0;
    }
  }