:root {
  --primary: #fec544;
  /* --primary:#08d665; */
  --secondary: #0a101e;
  /* --secondary:#0b0d0e; */
  --color1: #fdfeff;
  --color2: #172444;
  --color3:#472464;
  --color4:#161b1d;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Lato-Regular.ttf') format('truetype'); 
}
@font-face {
  font-family: 'Lato';
  font-style: bold;
  font-weight: 700;
  src: url('./assets/fonts/Lato-Bold.ttf') format('truetype'); 
}
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Oswald-Regular.ttf') format('truetype'); 
}
@font-face {
  font-family: 'Oswald';
  font-style: bold;
  font-weight: 700;
  src: url('./assets/fonts/Oswald-Bold.ttf') format('truetype'); 
}

body, html {
  font-family: 'Lato';
  font-size: 20px;
  line-height: 1.3;
  color:var(--color1);
  background-color: var(--secondary);
}
*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}
.link{
  text-decoration: none;
  color:inherit;
}
.container{
  width:70%;
  margin:0 auto;
}
.section{
  padding:80px 0;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Oswald';
  color:var(--primary);
  margin-bottom: 12px;
}
 
 
@media only screen and (max-width: 1440px) {
  .container{
    width:80%;
  }
}
@media only screen and (max-width: 1280px) {
  .section{
    padding:40px 0;
  }
}

@media only screen and (max-width: 1200px) {
  .container{
    width: 85%;
  }
}

@media only screen and (max-width: 764px) {
  .container{
    width:90%;
  }
}
 

