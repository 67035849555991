.offer-item{
    background-color: var(--color2);
    padding: 50px 30px;
    transition: .3s;
    position: relative;
}
.offer-item::after{
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 0px;
  height: 2px;
  margin: 0 auto;
  background-color: var(--primary);
  transition: all .3s ease-in-out;
}
.offer-item:nth-child(2)::after{
    background-color:var(--color2);
}
.offer-item:hover::after{
    width: 100%;
  }
 
.offer-item:nth-child(2){
    background-color: var(--primary);
    color:var(--secondary);
}
.item-icon{
    color:var(--primary);
    font-size:64px;
}
.offer-item:nth-child(2) .item-icon-big{
    color: var(--secondary);
}
.item-icon-big{
    color:var(--primary);
    font-size:20px;
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0;  
    transition: .3s; 
}
.offer-item:hover .item-icon-big{
    font-size:150px;
    right: 30px;
    bottom: -30px;
    opacity: 0.1;   
}
.offer-item:nth-child(2) .item-icon{
    color:var(--secondary);
}

.item-title, .offer-item:nth-child(2) .item-title{
    font-size:32px;
    font-weight: 700;
    margin-bottom: 20px;
}
@media only screen and (max-width: 1280px) {
    .offer-item{
        padding: 50px 10px;
    }
  }

