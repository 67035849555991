.realization-item{
    display: grid;
    grid-template-columns: 4fr 1fr;
    gap: 1rem;
    align-items: center;
}
.realization-item:nth-child(2n){
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 1rem;
}
.realization{
    border:1px solid var(--primary);
    min-height: 360px;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 5fr 8fr;
}
.realization-item:nth-child(2n) .realization{
    order:2;
    grid-template-columns: 8fr 5fr 1fr;
  }
.realization-item:nth-child(2n) .realization-bg{
  order:1;
  margin-right:50px;
}
.realization-item:nth-child(2n) .realization-info{
    order:1;
  }
.realization-item:nth-child(2n) .realization-img{
    order:2;
}
.realization-item:nth-child(2n) .realization-title{
    order:3;
}
.realization-bg{
    width:200px;
    height:200px;
    background-color: var(--primary);
    transform: rotate(-45deg);
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
   cursor: pointer;
  margin-left:50px;
}
.realization-bg::before{
    content: '';
    background-image: radial-gradient(var(--primary) 1px, transparent 0);
    background-size: 14px 14px; 
    width: 100px;
    height: 50px;
    position: absolute;
    bottom: -50px; 
}
.realization-item:nth-child(2n) .realization-bg::before{
    width: 50px;
    height: 100px;
    bottom: 50px;
    left: -56px;
  }
.realization-bg::after{
    content: '';
    background:var(--color2);
    width: 70px;
    height: 70px;
    position: absolute;
    top:20px;
    left: 165px;
    transition: .3s;
}
.realization-item:nth-child(2n) .realization-bg::after{
    top:-35px;
    left: 110px;
  }

 .realization-bg:hover::after {
    transform: rotate(90deg);
}
 
.realization-title{
    text-align: center;
    background-color: var(--primary);
    color:var(--secondary);
    font-size: 20px;
    font-weight: 700;
    writing-mode: vertical-rl;
    text-orientation:upright;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    display: flex;
    
}
.realization-title2{
    text-align: center;
    color:var(--secondary);
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    transform: rotate(45deg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;  
}
 
 .realization-img{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transition: .3s;
 }
 .realization:hover .realization-img{
        opacity: .8;
 }

.realization-icon{
    font-size: 30px;
    margin-top: 10px;
}
 
.realization-info{
    padding:20px;  
    text-align: center; 
    white-space: break-spaces;
    display: flex;
    align-self: center;
    justify-self: center;
}

@media only screen and (max-width: 1280px) {
    .realization-bg{
        width:160px;
        height:160px;
        margin-left:30px;
    }
    .realization-item:nth-child(2n) .realization-bg{
        margin-right:30px;
      }
      .realization-title2{
        font-size: 18px;
    }
    .realization-bg::after{
        top: 125px; 
        left: -35px;
    }
    .realization-item:nth-child(2n) .realization-bg::after{
        top: 125px;
        left:-35px;
      }
      .realization-bg::before{
        width: 100px;
        height: 30px;
        bottom: -30px; 
    }
    .realization-item:nth-child(2n) .realization-bg::before{
        width: 30px;
        height: 100px;
        bottom: 30px;
        left: -26px;
      }
  }

  @media only screen and (max-width: 900px) {
    .realization-item, .realization-item:nth-child(2n){
        grid-template-columns: 1fr;
    }
    .realization-bg, .realization-item:nth-child(2n) .realization-bg{
       order:1;
       margin:0 auto 100px;
    }
    
    .realization, .realization-item:nth-child(2n) .realization {
        order:2;
        grid-template-columns: none;
        grid-template-areas: 
            "title img img img "
            "info info info info";
     }
     .realization-title{
        writing-mode: unset;
        text-orientation: unset;
        grid-area: title;}
    .realization-img{
        grid-area: img;
        height: 200px;
    }
    .realization-info{
        grid-area: info;
    }
     }
     
    
    