.form{
    width: 600px;
    margin: 50px auto 0;
    text-align: left;
}
.form-group{
    display: flex;
    flex-direction: column;
}
.form-group label{
    margin-bottom: 6px;
}
.form-group input, .form-group textarea{
    margin-bottom: 12px;
    padding: 10px;
    font-family: 'Lato';
    font-size: 16px;
}
.acceptTerms label{
    margin-left:10px;
    font-size: 14px;
}
.form-btn{
    margin-top: 20px;
    display: block;
    font-size: 20px;
    font-family: 'Lato';
}
.form-error{
    color:var(--primary);
    margin-bottom: 10px;
}
.success-message{
    margin:50px auto 0;
    padding:20px;
    display: flex;
    justify-content: space-around;
    width:40%;
    border:1px solid var(--primary);
}
@media only screen and (max-width: 1024px) {
    .form{
        width: 90%;
        margin: 50px auto 0;
        text-align: left;
    }
  }