.hero{
    background-image: url('../../assets/img/hero.webp');
    background-repeat: no-repeat;
    background-size: cover;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
     justify-content: space-evenly;
}
.hero-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:75%;
}
.hero-title{
    font-size: 80px;
    text-transform: uppercase;
}
.hero-txt{
    font-size: 32px;
}
.hero-down-arrow{
    cursor: pointer;
    margin-top: 50px;
}

@media only screen and (max-width: 1200px) {
    .hero{
        height: inherit;
        padding: 80px 0 40px;
    }
    .hero-title{
        font-size: 62px;
        text-transform: uppercase;
    }
    .hero-txt{
        font-size: 30px;
    }
  }
@media only screen and (max-width: 764px) {
    
    .hero-info{
        text-align: center;
        width:86%;
        margin: 0 auto;
    }
    .hero-title{
        font-size: 48px;
        text-transform: uppercase;
    }
    .hero-txt{
        font-size: 24px;
    }
  }