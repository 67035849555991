.btn{
    background-color: transparent;
    color: var(--color1);
    text-transform: uppercase;
    font-weight: 700;
    padding: 20px 60px;
    width: fit-content;
    letter-spacing: 2px;
    cursor: pointer;
    transition: .3s;
    border: 1px solid var(--primary);
}
.btn2{
    background-color: var(--primary);
    color:var(--secondary);
    border: 1px solid var(--primary);
    position: relative;
  }

.btn:hover{
    background-color: var(--primary);
    color: var(--color1);
    
}
.btn2:hover{
    background-color: transparent;
    color:var(--color1);
  }

  .btn2::after{
    content: '';
    width: 125px;
    height: 80px;
    background-image: radial-gradient(var(--primary) 1px, transparent 0);
    background-size: 16px 16px;
    position: absolute;
    bottom: -30px;
    transition: 1s;
}
.btn2:hover::after{
  bottom: -30px;
  transform: rotate(90deg);
}
.buttons-group{
    margin-top:50px;
    display: flex;
    gap:1rem;
}

@media only screen and (max-width: 764px) {
  .buttons-group{
    flex-direction: column;
    align-items: center;

}
}

@media only screen and (max-width: 500px) {
  .btn2::after{
    content: '';
    width: 125px;
    height: 80px;
    background-image: radial-gradient(var(--primary) 1px, transparent 0);
    background-size: 16px 16px;
    position: absolute;
    bottom: -30px;
    right:-30px;
    transition: 1s;

  }
}
@media only screen and (max-width: 400px) {
  .btn{
    padding: 16px 40px;
  }
}