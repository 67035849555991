.about{
    height:inherit;
}
.about-intro{
    margin-top:100px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    align-items: center;
}
.about-offer{
    text-align: center;
    margin-top:180px;
}
.about-img{
    width: 450px;
    height: 300px;  
    margin-top: 40px;
    position: relative;
    z-index: 9;
    background-image: url('../../assets/img/about.png');
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(45deg);
    
    }
.about-bg{
    width: 400px;
    height: 400px;
    /* background-image: radial-gradient(var(--primary) 1px, transparent 0);
    background-size: 40px 40px; */
    border:1px solid var(--primary);
    transform: rotate(-45deg);
    z-index: 5;
    position: relative;
   
}
.btn-mobile{
    display: none;
}
.about-bg::after{
    content: '';
    width:100px;
    height: 100px;
    background: var(--primary);
    position: absolute;
    left: -2px;
    bottom: -50px;
    transform: rotate(-90deg);
}
.about-bg::before{
    content: '';
    width:100px;
    height:100px;
    background: var(--primary);
    position: absolute;
    right: -2px;
    top: -50px;
    transform: rotate(-90deg);
}
 
.about-title{
    font-size: 40px;
    margin-bottom: 30px;
}
.about-text{
    margin:0 20%;
}
.about-title span{
    color:var(--primary);
}
.about-intro .btn{
    position: relative;
    margin-top: 50px;

}
 
.offerItems{
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap:3rem;
}
@media only screen and (max-width: 1440px) {
    .about-intro{
        margin-top: 50px;
    }
    .about-intro .btn{
        margin-top: 30px;
    
    }
    .about-offer {
        margin-top: 120px;
      }
    .about-img{
        width: 450px;
        height: 300px;  
        margin-top: 40px; 
        }
    .about-bg{
        width:400px;
        height:400px;
    }
    .offerItems{
        gap:2rem;
    }
  }

  @media only screen and (max-width: 1280px) {
  
    .about-intro{
        margin-top: 40px;
        grid-template-columns: 2fr 3fr;
        gap: 4rem;
    }
 
    .about-img{
        width: 330px;
        height: 220px;  
        margin-top: 50px; 
        }
    .about-bg{
        width:320px;
        height:320px;
        justify-self: center;
    }
    .about-bg::after{
        width:60px;
        height: 60px;
        bottom: -30px;
    }
    .about-bg::before{
        width:60px;
        height:60px;
        top: -30px;
    }
    .offerItems{
        gap:1rem;
    }
  }
  @media only screen and (max-width: 1024px) {
  
    .about-intro{
        grid-template-columns: 1fr;
    }
    .about-title{
        order: 1;
        text-align: center;
    }
    .about-bg{
        order: 2;
        margin-top: 70px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-self: center;
        margin-left: -30%;
        margin-bottom: 50px;
    }
   
    .about-intro .btn{
        display: none;
       
    }
    .about  .btn-mobile{
        display: block;
        transform: rotate(45deg);
        position: absolute;
        top: 120%;
        left: 100px;
    }
    
  }

  @media only screen and (max-width: 764px) {
    .about-intro{
        text-align: center  ;
    }
    .about-intro .btn{
        display: block;
        margin: 50px auto;
       
    }
    .about  .btn-mobile{
        display: none;
    }
 
    .about-bg{
        margin: 50px auto;
    }
    .about-text{
        margin: 0 10%;
    }
    .about-offer{
        margin-top: 90px;
    }
    .offerItems{
        grid-template-columns: 1fr;
  
    }
  }
  @media only screen and (max-width: 600px) {
    .about-img{
        width: 300px;
        height: 200px;  
        margin-top: 50px;
        }
    .about-bg{
        width:300px;
        height:300px;
    }

  }

  @media only screen and (max-width: 500px) {
    .about-img{
        width: 200px;
        height: 133px;  
        margin-top: 40px;
        margin-left: 10px;
        }
    .about-bg{
        width:220px;
        height:220px;
    } 
  }
  @media only screen and (max-width: 380px) {
    .about-img{
        width: 160px;
        height: 107px;  
        margin-top: 40px;
        margin-left: 10px;
        }
    .about-bg{
        width:180px;
        height:180px;
    }
     
  }
  